import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import useFileOneSettings from "../../../../application/useFileOneSettings";
import styles from "./FileOneAccordion.module.scss";
import InvoiceSettingsDto from "../../../../infrastructure/dtos/FileOneSettings/InvoiceSettingsDto";

const FileOneAccordionComponent = () => {
  const { isUpdating, fileOneSettings, updateFileOneSettings, isFetching, isLoading } = useFileOneSettings();
  const { handleSubmit, register, reset } = useForm<InvoiceSettingsDto>({
    mode: "onChange",
  });

  useEffect(() => {
    if (!fileOneSettings?.invoice) return;
    reset(fileOneSettings.invoice);
  }, [fileOneSettings?.invoice, isLoading, reset]);

  const onSubmit = (data: InvoiceSettingsDto) => {
    if (!fileOneSettings) return;
    updateFileOneSettings({
      ...fileOneSettings,
      invoice: data,
    });
  };

  return (
    <Accordion disableGutters className={styles.accordion} defaultExpanded>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} className={styles.accordionSummary}>
        <Typography variant="h4" component="h1" className={styles.accordionSummaryContent}>
          <img src="/assets/logos/upload-files.svg" alt="FileOne logo" className={styles.accordionSummaryContentIcon} />
          FileOne
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <Typography variant="h5" component="h2" gutterBottom className={styles.accordionDetailDescription}>
          Mandatory fields
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h6" component="h3" gutterBottom>
            Customer
          </Typography>
          <label>
            <input type="checkbox" {...register("customer.account.isRequired")} />
            <Typography>Customer number</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.name.isRequired")} />
            <Typography>Name</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.organisationNumber.isRequired")} />
            <Typography>Company ID</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.address.address1.isRequired")} />
            <Typography>Street</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.address.address2.isRequired")} />
            <Typography>Street (additional)</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.address.buildingNumber.isRequired")} />
            <Typography>Building number</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.address.city.isRequired")} />
            <Typography>City</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.address.postalCode.isRequired")} />
            <Typography>Postal code</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.address.country.isRequired")} />
            <Typography>Country</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.address.countryCode.isRequired")} />
            <Typography>Country code</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.gln.isRequired")} />
            <Typography>GLN</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.taxIdNumber.isRequired")} />
            <Typography>VAT ID</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.contact.Email.isRequired")} />
            <Typography>Email</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("customer.contact.Phone.isRequired")} />
            <Typography>Phone</Typography>
          </label>
          <Typography variant="h6" component="h3" gutterBottom>
            Delivery
          </Typography>
          <input
            type="checkbox"
            className={styles.hidden}
            {...register("delivery.address.address2.isRequired")}
            disabled
            checked={false}
          />
          <input
            type="checkbox"
            className={styles.hidden}
            {...register("delivery.address.buildingNumber.isRequired")}
            disabled
            checked={false}
          />
          <label>
            <input type="checkbox" {...register("delivery.name.isRequired")} />
            <Typography>Name</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("delivery.date.isRequired")} />
            <Typography>Delivery Date</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("delivery.address.address1.isRequired")} />
            <Typography>Street</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("delivery.address.city.isRequired")} />
            <Typography>City</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("delivery.address.postalCode.isRequired")} />
            <Typography>Postal code</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("delivery.address.country.isRequired")} />
            <Typography>Country</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("delivery.address.countryCode.isRequired")} />
            <Typography>Country code</Typography>
          </label>
          <Typography variant="h6" component="h3" gutterBottom>
            Invoice
          </Typography>
          <input
            type="checkbox"
            {...register("creditIndicator.isRequired")}
            checked={false}
            className={styles.hidden}
            disabled
          />
          <label>
            <input type="checkbox" {...register("reference.isRequired")} />
            <Typography>Invoice number</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("paymentId.isRequired")} />
            <Typography>Payment reference</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("buyerReference.isRequired")} />
            <Typography>Buyer&apos;s reference</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("orderReference.isRequired")} />
            <Typography>Buyer&apos;s order reference</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("seller.ContactName.isRequired")} />
            <Typography>Seller&apos;s name</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("sellerOrderReference.isRequired")} />
            <Typography>Seller&apos;s order reference</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("issueDate.isRequired")} />
            <Typography>Invoice date</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("dueDate.isRequired")} />
            <Typography>Due date</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("documentCurrencyCode.isRequired")} />
            <Typography>Currency code</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("totalAmount.isRequired")} />
            <Typography>Total amount to pay</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("totalTaxAmount.isRequired")} />
            <Typography>Total VAT</Typography>
          </label>
          <Typography variant="h6" component="h3" gutterBottom>
            Line items
          </Typography>
          <label>
            <input type="checkbox" {...register("lineItems.discount.isRequired")} />
            <Typography>Discount %</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("lineItems.identifier.isRequired")} />
            <Typography>ID</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("lineItems.itemDescription.isRequired")} />
            <Typography>Item description</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("lineItems.quantity.isRequired")} />
            <Typography>Quantity</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("lineItems.totalAmount.isRequired")} />
            <Typography>Total</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("lineItems.unitCode.isRequired")} />
            <Typography>Unit code</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("lineItems.unitPrice.isRequired")} />
            <Typography>Unit price</Typography>
          </label>
          <label>
            <input type="checkbox" {...register("lineItems.vatPercentage.isRequired")} />
            <Typography>VAT %</Typography>
          </label>

          <Button type="submit" disabled={isUpdating || isFetching}>
            Save
          </Button>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default FileOneAccordionComponent;
